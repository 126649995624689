import React from 'react'
import "./styles.scss"

const Cartoon = () => {
    return (
        <>
          
	<div id="totoro">
    <div class="head">
        <div class="ear ear-left"></div>
        <div class="ear ear-right"></div>
        <div class="eye eye-left">
            <div class="pupil"></div>
        </div>
        <div class="eye eye-right">
            <div class="pupil"></div>
        </div>
        <div class="nose"></div>
        <div class="mouth">
            <div class="mouth-upper">
                <div class="tooth"></div>
                <div class="tooth"></div>
                <div class="tooth"></div>
                <div class="tooth"></div>
                <div class="tooth"></div>
                <div class="tooth"></div>
            </div>
            <div class="mouth-lower">
                <div class="tooth"></div>
                <div class="tooth"></div>
                <div class="tooth"></div>
                <div class="tooth"></div>
                <div class="tooth"></div>
                <div class="tooth"></div>
            </div>
        </div>
        <div class="whiskas whiskas-left">
            <div class="whiska whiska-top"></div>
            <div class="whiska"></div>
            <div class="whiska whiska-bottom"></div>
        </div>
        <div class="whiskas whiskas-right">
            <div class="whiska whiska-top"></div>
            <div class="whiska"></div>
            <div class="whiska whiska-bottom"></div>
        </div>
    </div>
    <div class="body">
        <div class="belly">
            <div class="chevron chevron-large chevron-left"></div>
            <div class="chevron chevron-large chevron-center"></div>
            <div class="chevron chevron-large chevron-right"></div>
            <div class="chevron chevron-small chevron-left"></div>
            <div class="chevron chevron-small chevron-center-left"></div>
            <div class="chevron chevron-small chevron-center-right"></div>
            <div class="chevron chevron-small chevron-right"></div>
        </div>
        <div class="arm arm-left">
            <div class="nail"></div>
            <div class="nail"></div>
            <div class="nail"></div>
        </div>
        <div class="arm arm-right">
            <div class="nail"></div>
            <div class="nail"></div>
            <div class="nail"></div>
        </div>
        <div class="leg leg-left"></div>
        <div class="leg leg-right"></div>
    </div>
</div>
</>
    )
}

export default Cartoon
