import { useState } from "react";
import "./works.scss";
import PortfolioImgFirst from "../../assets/porftolioImages/Firstportfolio.png";
import PortfolioImgSecond from "../../assets/porftolioImages/Secondportfolio.png";
import PortfolioImgThird from "../../assets/porftolioImages/Thirdportfolio.png";

export default function Works() {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const data = [
    {
      id: "1",
      title: "Third Portolio",
      link: "https://dantelentsoe.netlify.app/",
      desc:
        "The period where I gain confidence to get a full time developer job",
      img:
      PortfolioImgThird,
        alt: "Dante Lentsoe Third Portfolio"
    },
    {
      id: "2",
      title: "Second Portfolio",
      link: "https://dante-lentsoe-collections.netlify.app/",
      desc:
        "APIs and learning about more than front end development",
      img:
      PortfolioImgSecond,
        alt: "Dante Lentsoe Second Portfolio"
    },
    {
      id: "3",
      title: "Inital Portfolio",
      link: "https://codepen.io/dante_lentsoe/full/Vwaordg",
      desc:
        "The period of 'Hello World'",
      img:
      PortfolioImgFirst,
      alt: "Dante Lentsoe First Portfolio"
    },
  ];

  const handleClick = (way: string) => {
    way === "left"
      ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 2)
      : setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0);
  };
  
  return (
    <>
    <div className="works" id="works">
      
      <div
        className="slider"
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {data.map((d) => (
          <>
          <div className="container">
            <div className="item">
   
              <div className="left">
                <div className="leftContainer">
                 
                  <h2>{d.title}</h2>
                   <p>{d.desc}</p>
                </div>
              </div>
              <div className="right">
                <a href={d.link}  className="img-container-scroll">
                <img
                  src={d.img}
                  alt={d.alt}
                />
                </a>
              </div>
            </div>
          </div>
            </>
        ))}
      </div>
      <img
        src="assets/arrow.png"
        className="arrow left"
        alt=""
        onClick={() => handleClick("left")}
      />
      <img
        src="assets/arrow.png"
        className="arrow right"
        alt=""
        onClick={() => handleClick("")}
      />
    </div>
  </>);
}
