import Topbar from "./components/topbar/Topbar";
import Intro from "./components/intro/Intro"
import Portfolio from "./components/portfolio/Portfolio"
import Works from "./components/works/Works"
import Timeline from "./components/about"
import Techonologies from "./components/technology"
import Contact from "./components/contact/Contact"
import "./app.scss"
import { useState } from "react";
import Menu from "./components/menu/Menu";
import Footer from './components/footer';
import Theme from "./styles/theme";
import PersonalGoals from "./components/goals"
function App() {
  const [menuOpen,setMenuOpen] = useState<Boolean>(false)
  return (
    <Theme>
    <div className="app">
     <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
     <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
     <div className="sections">
          <Intro />
          <Timeline />
       <Portfolio/>
       <Works/>
       <Techonologies/>
          <Contact />
         
          <div className="section-footer">
          <PersonalGoals />
          <Footer/>
          </div>
        </div>
        
      </div>
      </Theme>
  );
}

export default App;
