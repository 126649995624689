import React from "react";

import {
  Section,
  SectionDivider,
  SectionTitle,
} from "../../styles/GlobalComponents";
import { Box, Boxes, BoxNum, BoxText } from "./styles";

const data = [
  { number: 2, text: "Open Source Projects" },
  { number: 100, text: "Teach 100+ Students To Code" },
  { number: 190, text: "Acquire 190+ Github Followers" },
  { number: 2000, text: "Get Github Stars" },
];

const PersonalGoals = () => (
  <Section>
    <SectionTitle>Personal Goals</SectionTitle>
    <SectionDivider />
    <Boxes>
      {data.map((card, index) => (
        <Box key={index}>
          <BoxNum>{`${card.number}+`}</BoxNum>
          <BoxText>{card.text}</BoxText>
        </Box>
      ))}
    </Boxes>
  </Section>
);

export default  PersonalGoals;
