import { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./contact.scss";

export default function Contact() {
  const [message, setMessage] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_6az72md",
        "template_80kfr4c",
        e.target,
        "user_6pfmIyxWoeG1LaH2IhVyK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          toast.error("Message Not Send :-(", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    setMessage(true);
    toast.success("Message Send :-)", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <div className="contact" id="contact-form">
      <div className="left">
        <img src="assets/shake.svg" alt="" />
      </div>
      <div className="right">
        <h2>Contact.</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="Name" name="user_name" required />
          <input type="email" placeholder="Email" name="user_email" required />
          <textarea placeholder="Message" name="message" required></textarea>
          <button type="submit" value="Send">
            Send
          </button>
          {message === true && <span>Thank you, I will reply ASAP :-)</span>}
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
