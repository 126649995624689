import "./topbar.scss";
import  Mail  from "@material-ui/icons/Mail";
import  Person  from "@material-ui/icons/Person";
import DescriptionIcon from '@material-ui/icons/Description';
export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            Dante.
          </a>
          <div className="itemContainer">
            <Person className="icon" />
            <span><a href="tel:+27812195594" className="navlink">Call Me</a></span>
          </div>
          <div className="itemContainer">
            <Mail className="icon" />
            <span><a href="mailto:dllentsoe@gmail.com?subject=Reaching out to work with you" className="navlink">Email Me </a></span>
          </div>
          <div className="itemContainer">
            <DescriptionIcon className="icon" />
            <span><a download href="https://drive.google.com/file/d/1SwqiRkTwsMZDBszHrNFo8vTbRN264zYr/view?usp=sharing" className="navlink">Get Resume </a></span>
          </div>
        </div>
        <div className="right">
          <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
